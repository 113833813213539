import { z } from 'zod';
import { objKeys } from './utility';
// highTODO are we doing ULIDs, KSUID, or neither?
export const dateSchema = z.preprocess((arg) => {
    if (typeof arg === 'string' || arg instanceof Date)
        return new Date(arg);
}, z.date());
export const remoteNoteId = z
    .string()
    .regex(/^[a-zA-Z0-9_-]{22}$/);
export const remoteTemplateId = z
    .string()
    .regex(/^[a-zA-Z0-9_-]{22}$/);
export const commentId = z
    .string()
    .regex(/^[a-zA-Z0-9_-]{22}$/);
const fieldValues = z
    .record(z.string().min(1), z.string())
    .refine((x) => objKeys(x).length > 0);
const noteUneditable = {
    id: true,
    templateId: true,
    created: true,
    edited: true,
    nook: true,
};
export const nookIdRegex = /^[a-z][a-z0-9]{2,21}$/;
export const nookId = z
    .string()
    .regex(nookIdRegex);
export const remoteNote = z.object({
    id: remoteNoteId,
    nook: nookId,
    templateId: remoteTemplateId,
    created: dateSchema,
    edited: dateSchema,
    fieldValues,
    tags: z.array(z.string()),
    ankiId: z.number().positive().optional(),
});
export const createRemoteNote = remoteNote.omit(noteUneditable).extend({
    localId: z.string(),
    remoteTemplateIds: z.array(remoteTemplateId).min(1),
});
export const editRemoteNote = remoteNote.omit(noteUneditable).extend({
    remoteIds: z.map(remoteNoteId, remoteTemplateId).refine((x) => x.size > 0),
});
export const childTemplate = z.object({
    id: z.number().int(),
    name: z.string(),
    front: z.string(),
    back: z.string(),
    shortFront: z.string().optional(),
    shortBack: z.string().optional(),
});
const standard = z.object({
    tag: z.literal('standard'),
    templates: z.array(childTemplate).min(1),
});
const cloze = z.object({
    tag: z.literal('cloze'),
    template: childTemplate,
});
export const templateType = z.discriminatedUnion('tag', [standard, cloze]);
export const remoteTemplate = z.object({
    id: remoteTemplateId,
    nook: nookId,
    created: dateSchema,
    edited: dateSchema,
    name: z.string(),
    templateType,
    fields: z.array(z.string()),
    css: z.string(),
    ankiId: z.number().positive().optional(),
});
const templateUneditable = {
    id: true,
    nook: true,
    created: true,
    edited: true,
};
export const createRemoteTemplate = remoteTemplate
    .omit(templateUneditable)
    .extend({
    localId: z.string(),
    nooks: z.array(nookId),
});
export const editRemoteTemplate = remoteTemplate
    .omit(templateUneditable)
    .extend({
    remoteIds: z.array(remoteTemplateId).min(1),
});
export const commentText = z.string().min(1).max(1000);
